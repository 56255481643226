

import AxiosInstance, { getAxiosConfig } from "@/instance/instance";
import { GameType } from "@/routes/game/type";
import { OperatedCitiesType } from "../getOperatedCities";

export interface SearchAreaItemDataType{
        _id:string;
        cityId: string;
        locality_name: string;
        coordinates: {
            type: string;
            coordinates: number[]
        },
        zone: string;
        status: boolean;
        __v: number;
        score:number;
        extraCity?:string;
    
}

export interface searchAreaBodyType{
    city:OperatedCitiesType;
    area:string;
}
export interface searchAreaDataType {
    data:{
        data:SearchAreaItemDataType[];
        message:string;
        status:string;
    }
 
}


export const searchAreaCityWise = async (city:OperatedCitiesType, searchText:string): Promise<SearchAreaItemDataType[]> => {
    try {
        let body :searchAreaBodyType = {
            city:city,
            area:searchText
        }
        const response: searchAreaDataType = await AxiosInstance.post(
            'v2/venue/search_city_and_area',
            body,
            getAxiosConfig(true),
        );
   
        return response?.data?.data;
    } catch (error) {
        throw error;
    }
};
