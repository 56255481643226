import classNames from 'classnames';
import Link from 'next/link'
import React from 'react'
import styles from './PopularAreasInCity.module.scss';
import Image from 'next/image';

interface CityItemPropType {
    title: string;
    link: string;
    handleClick: () => void;
}
const AreaItem = ({ title, link, handleClick }: CityItemPropType) => {
    return (
        <div onClick={handleClick} className={classNames(styles['row'], styles['align-center'], styles['cursor-pointer'], styles['deco-none'], styles['mb-20'], styles['justify-space-between'])} >
            <div className={classNames(styles['row'], styles['align-center'])}>
                <Image height={32} width={32} className={styles['mr-16']} priority src={'/svgs/location_pin_icon.svg'} alt='whitegps' />
                <p className={classNames(styles['sub_6'], styles['white1'])}>{title}</p>
            </div>
        </div>
    )
}

export default AreaItem