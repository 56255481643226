'use client'
import React, { useContext, useRef, useMemo, useEffect } from 'react';
//styles
import styles from './venueGpsSearch.module.scss';
import classNames from 'classnames';
//Images
import Image from 'next/image';
import dropdown from '../../../../../public/explore_venues/Polygon1.png';
import bluelocate from '../../../../../public/explore_venues/bluelocate.webp';

//Modal
import VenueGpsModal from './VenueGpsModal';
import { ExplorepageType } from '@/routes/exploreComponent/exploreComponent';
import { OperatedCitiesType } from '@/apis/getOperatedCities';

export enum type {
  SPORT,
  VENUES
}
export interface VenueGpsSearchPropType {
  city: string | undefined | null;
  type: ExplorepageType;
  cityName: string;
  sendData: (popularAreas: OperatedCitiesType[], selectedCity: OperatedCitiesType) => void
}

const VenueGpsSearch = ({ city, type, cityName, sendData }: VenueGpsSearchPropType) => {

  const modalRef = useRef<null | { openModal: () => void }>();

  const modifiedCity = useMemo(() => {
    let str = city ? city : ''
    let tempcity = str?.replaceAll('-', ' ');
    tempcity = tempcity.charAt(0).toUpperCase() + tempcity.slice(1);

    return tempcity;
  }, [city])

  return (
    <>

      <div className={classNames(styles.location_alignment)}>
        <div className={styles.enter_location_mobile}>YOUR LOCATION</div>
        <div onClick={() => {
          if (modalRef?.current) {
            modalRef?.current?.openModal();
          }
          //setModal(true)
        }} className={classNames(styles.search_location, styles['cursor-pointer'])} >
          <Image className={classNames(styles.gps_icon, styles['mr-8'])} height={19} width={14} onClick={() => { }} src={bluelocate} alt='' />
          <p className={styles.current_location}>
            {modifiedCity}
          </p>
          <Image className={classNames(styles.dropdown)} src={dropdown} alt='dropdown' />
        </div>
      </div>

      <VenueGpsModal
        ref={modalRef}
        type={type}
        cityName={cityName}
        sendData={sendData} />
    </>
  )
}

export default React.memo(VenueGpsSearch)