import { useCallback } from 'react'
import styles from './ModalcitiesOperated.module.scss';
import classNames from 'classnames';
import CityItem from './cityItem';
import { OperatedCitiesType } from '@/apis/getOperatedCities';
import { ExplorepageType } from '@/routes/exploreComponent/exploreComponent';
import { useRouter } from 'next/router';

interface ModalcitiesOperatedPropType {
    cities: OperatedCitiesType[];
    type: ExplorepageType;
    OnChange: () => void;
}

const ModalcitiesOperated = ({ cities, type, OnChange }: ModalcitiesOperatedPropType) => {
    const router = useRouter();

    const handleLink = useCallback((url: string) => {
        OnChange();
        router.push(url).then(() => {
            router.reload();
        })
        // setTimeout(() => {
        //     router.reload()
        // }, 600)
    }, [OnChange, router]);


    return (
        <div className={classNames(styles['container'])}>
            <p className={classNames(styles['mb-28'], styles['button_2'], styles['grey4'])}>OTHER CITIES WE OPERATE IN</p>
            <div className={classNames(styles['column'], styles['width_100'])}>
                {cities?.map((item, index) => {
                    let url = '';
                    if (type == ExplorepageType.VENUES) {
                        url = `/${item.city.trim().toLocaleLowerCase().replaceAll(' ', '-')}/sports-venues`;
                    } else if (type == ExplorepageType.GAMES) {
                        url = `/game-list/${item.city.trim().toLocaleLowerCase().replaceAll(' ', '-')}`;
                    }
                    return (
                        <CityItem key={index} title={item?.city} link={url} handleClick={() => { handleLink(url) }} />
                    )
                })}
            </div>
        </div>
    )
}

export default ModalcitiesOperated