import React, { useState, useCallback, useEffect, useMemo } from 'react'
import styles from './venueGpsSearch.module.scss';
import classNames from 'classnames';
import Image from 'next/image';
import PopularAreasInCity from '../PopularAreasInCity';
import { ExplorepageType } from '@/routes/exploreComponent/exploreComponent';
import { OperatedCitiesType } from '@/apis/getOperatedCities';
import { searchAreaCityWise, SearchAreaItemDataType } from '@/apis/searchAreaCityWise';
import Loading from './loading';
import AreaItem from '../PopularAreasInCity/AreaItem';
import { useRouter } from 'next/router';
import Cookies from 'js-cookie';
import RecentSearch from '../RecentSearch';
interface SearchGpsPropType {
    type: ExplorepageType;
    popularAreas: OperatedCitiesType[];
    onBack: () => void;
    selectedCity: OperatedCitiesType;
    closeModal: () => void;
}
const SearchGps = ({ type, popularAreas, onBack, selectedCity, closeModal }: SearchGpsPropType) => {
    const router = useRouter();

    const [search, setSearch] = useState<string>("");
    const [results, setResults] = useState<{ loading: boolean, data: SearchAreaItemDataType[] | undefined }>({ loading: false, data: undefined });


    const getRecentSearch = useMemo(() => {
        const recent_search = Cookies.get('RECENT_SEARCH');
        if (recent_search) {
            return JSON.parse(recent_search);
        } else {
            return [];
        }

    }, [])

    const setRecentSearch = useCallback((data: SearchAreaItemDataType) => {
        const recent_search = Cookies.get('RECENT_SEARCH');
        let array: SearchAreaItemDataType[] = []
        if (recent_search) {
            array = JSON.parse(recent_search);
            let foundItem = array.find((i) => i?._id == data?._id);
            if (!foundItem) {
                array.unshift(data);

            }
        } else {
            array.unshift(data);

        }
        if (array.length > 3) {
            array = array.slice(0, 3);
        }
        Cookies.set('RECENT_SEARCH', JSON.stringify(array));

    }, [])

    const searchArea = useCallback(async () => {
        try {
            setResults({ loading: true, data: undefined })
            const res = await searchAreaCityWise(selectedCity, search);
            setResults({ loading: false, data: res })
        } catch (e) {
            setResults({ loading: false, data: undefined })
        }
    }, [search, selectedCity]);

    useEffect(() => {

        const getData = setTimeout(() => {
            if (search && search.length > 2) {
                searchArea()
            } else {
                setResults({ loading: false, data: undefined })
            }
        }, 400);

        return () => clearTimeout(getData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    const handleinput = (event: { target: { value: any; }; }) => {
        setSearch(event.target.value)
    };

    const handleClear = useCallback(() => {
        setSearch('');
        setResults({ loading: false, data: undefined })
    }, [])



    const handleLink = useCallback((data: SearchAreaItemDataType, url: string) => {
        setRecentSearch(data)
        closeModal();
        router.push(url).then(() => {
            router.reload();
        })
    }, [closeModal, router, setRecentSearch])

    const renderResultView = useCallback(() => {
        if (results?.loading) return <Loading />;
        if (results?.data && results?.data?.length > 0) {
            return (
                <>
                    {search !== "" && (
                        <>
                            {results?.data?.map((item: SearchAreaItemDataType, index) => {
                                let url = '';
                                if (type == ExplorepageType.VENUES) {
                                    url = `/${selectedCity?.city.trim().toLocaleLowerCase().replaceAll(' ', '-')}/${item?.locality_name.trim().toLocaleLowerCase().replaceAll(' ', '-')}/sports-venues`;
                                } else if (type == ExplorepageType.GAMES) {
                                    url = `/game-list/${selectedCity?.city.trim().toLocaleLowerCase().replaceAll(' ', '-')}/${item?.locality_name.trim().toLocaleLowerCase().replaceAll(' ', '-')}`;
                                }
                                let storageItem = { ...item, extraCity: selectedCity?.city.trim().toLocaleLowerCase().replaceAll(' ', '-') }
                                return (
                                    <>
                                        <AreaItem key={index} title={item?.locality_name} link={url} handleClick={() => { handleLink(storageItem, url) }} />
                                    </>
                                )
                            })}
                        </>

                    )}
                </>
            )
        } else if (results?.data && results?.data?.length == 0) {
            return <div className={classNames(styles['sectiontitle'], styles['white1'], styles['mt-24'], styles['ml-20'])}>No results found</div>
        } else {
            return <></>
        }
    }, [handleLink, results?.data, results?.loading, search, selectedCity?.city, type]);

    return (
        <div className={styles['search_cont']}>
            <div onClick={onBack} className={classNames(styles['row'], styles['align-center'], styles["cursor-pointer"], styles['mb-44'])}>
                <Image width={14} height={11} src={'/svgs/left_arrow.svg'} alt="close" className={styles['mr-16']}
                />
                <p className={classNames(styles['title_2'], styles['white2'])}>Pick an area in {selectedCity?.city ? selectedCity?.city : ''}</p>
            </div>
            <div className={classNames(styles.offcanvasInput, styles['mb-40'])}>
                <input
                    role='searchbox'
                    placeholder={`Search for areas in ${selectedCity?.city ? selectedCity?.city : ''}`}
                    value={search}
                    onChange={handleinput}
                    className={styles.search_input} />
                {
                    search !== "" &&
                    (<Image onClick={handleClear} className={classNames(styles["cursor-pointer"])} style={{ marginTop: -4 }} alt='close' width={28} height={28} src={'/svgs/close-circle.svg'} />)

                }
            </div>
            {renderResultView()}
            {!results?.loading && !results?.data && (
                <>
                    <RecentSearch title={'Recent searches'} seeMore={false} type={type} handleSeeMore={() => { }} cities={getRecentSearch} border={false} OnChange={closeModal} />
                    <PopularAreasInCity title={`POPULAR AEARS IN ${selectedCity?.city}`} seeMore={false} citySelected={selectedCity} type={type} handleSeeMore={() => { }} cities={popularAreas} border={false} OnChange={closeModal} />
                </>
            )}
            {results?.data && (
                <div className={classNames(styles['note_cont'], styles['mt-60'])}>
                    <p className={classNames(styles['sub'], styles['orange2'], styles['orange_underline'], styles['mb-6'])}>Note</p>
                    <p className={classNames(styles['sub'], styles['white13'])}>Only showing areas from {selectedCity?.city}</p>
                </div>
            )}
        </div>
    )
}

export default SearchGps