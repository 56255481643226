

import AxiosInstance, { getAxiosConfig } from "@/instance/instance";
import { OperatedCitiesType } from "../getOperatedCities";


export interface GetOperatedCitiesDataType {
    data:{
        data:OperatedCitiesType[];
        message:string;
        status:string;
    }
 
}

export const getCityPopularAreas = async (body:OperatedCitiesType): Promise<OperatedCitiesType[]> => {
    try {

        const response: GetOperatedCitiesDataType = await AxiosInstance.post(
            'v2/venue/popular_areas',
            body,
            getAxiosConfig(true),
        );
   
        return response?.data?.data;
    } catch (error) {
        throw error;
    }
};
