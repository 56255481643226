import { useCallback, useState, useEffect } from 'react'
import styles from './RecentSearch.module.scss';
import classNames from 'classnames';
import AreaItem from '../PopularAreasInCity/AreaItem';
import { getCityPopularAreas } from '@/apis/getCityPopularAreas';
import { OperatedCitiesType } from '@/apis/getOperatedCities';
import { useRouter } from 'next/router';
import { ExplorepageType } from '@/routes/exploreComponent/exploreComponent';
import { SearchAreaItemDataType } from '@/apis/searchAreaCityWise';

interface RecentSearchPropType {
    title: string;
    seeMore: boolean;
    cities: SearchAreaItemDataType[];
    type: ExplorepageType;
    handleSeeMore: () => void;
    border: boolean;
    OnChange: () => void;
}

const RecentSearch = ({ title, seeMore, type, handleSeeMore, cities, border, OnChange }: RecentSearchPropType) => {
    const router = useRouter();

    const handleLink = useCallback((url: string) => {
        OnChange();
        router.push(url).then(() => {
            router.reload();
        })
    }, [OnChange, router])

    if (cities?.length == 0) return <></>

    return (
        <div className={classNames(styles['container'], border && styles['borderBottom'])}>
            <p className={classNames(styles['mb-28'], styles['button_2'], styles['grey4'], styles['text_uppercase'])}>{title}</p>
            <div className={classNames(styles['column'], styles['width_100'], styles['mb-4'])}>
                {cities?.map((item, index) => {
                    let url = '';
                    if (type == ExplorepageType.VENUES) {
                        url = `/${item?.extraCity?.trim().toLocaleLowerCase().replaceAll(' ', '-')}/${item?.locality_name.trim().toLocaleLowerCase().replaceAll(' ', '-')}/sports-venues`;
                    } else if (type == ExplorepageType.GAMES) {
                        url = `/game-list/${item?.extraCity?.trim().toLocaleLowerCase().replaceAll(' ', '-')}/${item?.locality_name.trim().toLocaleLowerCase().replaceAll(' ', '-')}`;
                    }
                    return (
                        <AreaItem key={index} title={item?.locality_name} link={url} handleClick={() => { handleLink(url) }} />
                    )
                })}
            </div>
            {seeMore && <p onClick={handleSeeMore} className={classNames(styles['sub'], styles['grey5'], styles['cursor-pointer'])}>See more</p>}
        </div>
    )
}

export default RecentSearch