import classNames from 'classnames';
import Link from 'next/link'
import React from 'react'
import styles from './ModalcitiesOperated.module.scss';
import Image from 'next/image';

interface CityItemPropType {
    title: string;
    link: string;
    handleClick: () => void;
}
const CityItem = ({ title, link, handleClick }: CityItemPropType) => {
    return (
        <div onClick={handleClick} className={classNames(styles['row'], styles['align-center'], styles['cursor-pointer'], styles['deco-none'], styles['mb-32'], styles['justify-space-between'])} >
            <div className={classNames(styles['row'], styles['align-center'])}>
                <Image height={21} width={15} src={'/svgs/location_purple.svg'} alt={'location'} />
                <p className={classNames(styles['sub_6'], styles['white1'], styles['ml-16'])}>{title}</p>
            </div>
            <Image height={8} width={8} src={'/svgs/right-arrow.svg'} alt={'arrow'} />
            {/* {loading && spinner()} */}
        </div>
    )
}

export default CityItem