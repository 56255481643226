import React, { useCallback } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import styles from './venueGpsSearch.module.scss'
import classNames from 'classnames';

function Loading() {

    const item = useCallback((border: boolean) => {
        return (
            <div className={classNames(styles['row'], styles['mb-24'], styles['mt-24'])}>
                <div style={{ width: 48, height: 48, borderRadius: 10, marginRight: 16, background: '#272A2E' }} />
                <div className={classNames(border && styles['borderBottom'])}>
                    <div style={{ width: 240, height: 16, borderRadius: 2, marginBottom: 4, background: '#272A2E' }} />
                    <div style={{ width: 170, height: 15, borderRadius: 2, background: '#272A2E' }} className={styles['mb-32']} />
                </div>

            </div>
        )
    }, [])
    return (
        <div>
            {item(true)}
            {item(true)}
            {item(false)}
        </div>
    );
}

export default Loading;
