
import OffcanvasModal from '@/components/common/offcanvasModal/offcanvasModal';
import React, { useCallback, useImperativeHandle, forwardRef, useMemo, useState, useEffect } from 'react'
//icons
import { ExplorepageType } from '@/routes/exploreComponent/exploreComponent';
import LocateGps from './LocateGps';
import SearchGps from './SearchGps';
import { getOperatedCities, OperatedCitiesType } from '@/apis/getOperatedCities';
import { getCityPopularAreas } from '@/apis/getCityPopularAreas';

declare global {
    interface Window {
        mappls: any;
    }
}

interface VenueGpsModalPropType {
    type: ExplorepageType;
    cityName: string;
    sendData: (popularAreas: OperatedCitiesType[], selectedCity: OperatedCitiesType) => void
}

const VenueGpsModal = (({ type, cityName, sendData }: VenueGpsModalPropType, ref: React.Ref<unknown> | undefined) => {

    const [showModal, setModal] = useState<boolean>(false);
    const [seeMore, setSeeMore] = useState<boolean>(false);
    const [data, setData] = useState<{ cities: OperatedCitiesType[], popularAreas: OperatedCitiesType[] }>({
        cities: [],
        popularAreas: []
    })

    const openModal = useCallback(() => {
        setModal(true)
    }, []);

    const closeModal = useCallback(() => {
        setModal(false);
        setSeeMore(false);
    }, [])

    useImperativeHandle(ref, () => ({
        closeModal,
        openModal,
    }));

    let selectedCity = useMemo(() => {
        return data?.cities.find((i) => i?.city?.toLocaleLowerCase() == cityName);
    }, [cityName, data?.cities])


    const getCities = useCallback(async () => {
        try {
            const res = await getOperatedCities();
            let areaRes = undefined;
            if (res) {
                let selected = res.find((i) => i?.city?.toLocaleLowerCase() == cityName);
                if (selected) {
                    areaRes = await getCityPopularAreas(selected);
                    sendData(areaRes, selected);
                }

            }
            setData({ cities: res, popularAreas: areaRes ? areaRes : [] });
        } catch (e) {
            console.error('getCities error', e);
        }
    }, [cityName, sendData]);

    useEffect(() => {
        getCities();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cityName])


    return (
        <>
            <OffcanvasModal show={showModal} onClose={closeModal}>
                {!seeMore && <LocateGps onBack={closeModal} cities={data?.cities} type={type} cityName={cityName} popularAreas={data?.popularAreas} handleSeeMore={() => { setSeeMore(true); }} selectedCity={selectedCity} closeModal={closeModal} />}
                {seeMore && selectedCity && <SearchGps type={type} popularAreas={data?.popularAreas} onBack={() => { setSeeMore(false); }} selectedCity={selectedCity} closeModal={closeModal} />}
            </OffcanvasModal>
        </>
    )
})

export default forwardRef(VenueGpsModal)

