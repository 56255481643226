import React, { useState, useCallback, useMemo } from 'react'
import styles from './venueGpsSearch.module.scss';
import classNames from 'classnames';
import Image from 'next/image';
import Spinner from '@/routes/exploreComponent/locationSearch/spinner';
import ModalcitiesOperated from '../ModalcitiesOperated/ModalcitiesOperated';
import PopularAreasInCity from '../PopularAreasInCity';
import { mapMyReverseGeo, MapMyRevGeoType } from '@/apis/mapMyReverseGeo';
import { OperatedCitiesType } from '@/apis/getOperatedCities';
import { ExplorepageType } from '@/routes/exploreComponent/exploreComponent';
import { capitalise } from '@/helper/capitalise';
import { MapMySearchType } from '@/apis/MapMySearch';
import { useRouter } from 'next/router';

interface LocateGpsPropType {
    onBack: () => void;
    cities: OperatedCitiesType[];
    popularAreas: OperatedCitiesType[];
    selectedCity: OperatedCitiesType | undefined;
    type: ExplorepageType;
    cityName: string;
    handleSeeMore: () => void;
    closeModal: () => void;
}
const LocateGps = ({ onBack, cities, type, cityName, handleSeeMore, popularAreas, selectedCity, closeModal }: LocateGpsPropType) => {
    const router = useRouter();
    const [spinner, setSpinner] = useState<boolean>(false);

    const handle = useCallback(
        (location: MapMySearchType | MapMyRevGeoType) => {
            if (type == ExplorepageType.VENUES) {
                if (location?.locality && location?.city) {
                    router.push(`/${location?.city.toLocaleLowerCase().replaceAll(' ', '-')}/${location?.locality.toLocaleLowerCase().replaceAll(' ', '-')}/sports-venues`).then(() => {
                        router.reload()
                    });
                } else if (location?.city) {
                    router.push(`/${location?.city.toLocaleLowerCase().replaceAll(' ', '-')}/sports-venues`).then(() => {
                        router.reload()
                    });
                }
            } else if (type == ExplorepageType.GAMES) {
                if (location?.locality && location?.city) {
                    router.push(`/game-list/${location?.city.toLocaleLowerCase().replaceAll(' ', '-')}/${location?.locality.toLocaleLowerCase().replaceAll(' ', '-')}`).then(() => {
                        router.reload()
                    });
                } else if (location?.city) {
                    router.push(`/game-list/${location?.city.toLocaleLowerCase().replaceAll(' ', '-')}/sports-venues`).then(() => {
                        router.reload()
                    });
                }
            }
            closeModal();
        },
        [closeModal, router, type]
    );

    const getCity = useCallback(async (lat: number, lon: number) => {
        try {
            const res = await mapMyReverseGeo(lat, lon);
            if (res?.length > 0) {
                handle(res[0]);
            }

            setSpinner(false);
        } catch (e) {
            setSpinner(false);
            console.error('search error', e);
        }
    }, [handle]);



    const getLocation = useCallback(() => {
        if (navigator.geolocation) {
            setSpinner(true);
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    getCity(position?.coords?.latitude, position?.coords?.longitude);

                },
                (error) => {
                    setSpinner(false);
                    if (error.code === 1) {
                        alert('User denied geolocation access.');
                    } else {
                        alert('Error getting geolocation.');
                    }
                }
            );
        } else {
            setSpinner(false);
            alert('Geolocation is not supported.');
        }
    }, [getCity]);

    let otherCities = useMemo(() => {
        return cities.filter((i) => i?.city.toLocaleLowerCase() != cityName);
    }, [cities, cityName])

    return (
        <div >
            <div onClick={onBack} className={classNames(styles['row'], styles['align-center'], styles["cursor-pointer"], styles['mb-36'])}>
                <Image width={14} height={11} src={'/svgs/left_arrow.svg'} alt="close" className={styles['mr-16']}
                />
                <p className={classNames(styles['title_2'], styles['white2'], styles['mt-8'])}>Set Location</p>
            </div>
            <div className={classNames(styles.locate_button, styles['mb-44'], spinner && styles['justify-center'])}>
                {spinner ? (
                    <Spinner />
                ) : (
                    <>
                        <Image className={styles['mr-12']} height={20} width={20} src={'/svgs/green_gps.svg'} alt='gps' />
                        <div
                            onClick={getLocation}
                            className={classNames(
                                styles.locateme,
                                styles['cursor-pointer'],
                                styles['mr-12']
                            )}
                        >
                            Use Current Location
                        </div>
                        <Image width={16} height={13} src={'/svgs/right_arrow_2.svg'} alt='gps' />
                    </>
                )}
            </div>
            <PopularAreasInCity title={`Popular areas in ${capitalise(cityName)}`} seeMore={true} citySelected={selectedCity} type={type} handleSeeMore={handleSeeMore} cities={popularAreas} border={true} OnChange={closeModal} />
            <div className={styles['mt-36']} />
            <ModalcitiesOperated cities={otherCities} type={type} OnChange={closeModal} />
        </div>
    )
}

export default LocateGps